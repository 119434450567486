import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
//import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {reducers, addReducerSet} from 'meld-clients-core/lib/reducers';
import pieceReducers  from './reducers/piece_relations';
import DeliusEssay from './containers/musicology/deliusInPerformance';
addReducerSet('pieces', pieceReducers);
//import App from './containers/app';

const createStoreWithMiddleware = applyMiddleware(thunk, ReduxPromise)(createStore);

ReactDOM.render(
  <Provider store={createStore(reducers, applyMiddleware(thunk, ReduxPromise))}>
    <DeliusEssay />
  </Provider>	
	, document.querySelector('.container'));
/*
ReactDOM.render(
	<Provider store={createStoreWithMiddleware(reducers)}>
		<BrowserRouter>
			  <Route path="/" component={DeliusEssay} />
		</BrowserRouter>
	</Provider>
	, document.querySelector('.container'));
*/
