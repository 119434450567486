import React, { Component } from 'react';
import { connect } from 'react-redux' ;
import { bindActionCreators } from 'redux';
import { Media, Player, controls, utils } from 'react-media-player'
import Score from 'meld-clients-core/lib/containers/score';
//import TEI from '../containers/tei';
import {prefix as pref} from 'meld-clients-core/lib/library/prefixes';
import MyImage from 'meld-clients-core/lib/containers/image';
//import IIIF from 'meld-clients-core/lib/components/iiif';
import TimeSensitiveImage from 'meld-clients-core/lib/containers/timeSensitiveImage'
import { fetchGraph, fetchTargetExpression, tickTimedResource } from 'meld-clients-core/lib/actions/index';
const { PlayPause, CurrentTime, Progress, SeekBar, Duration, MuteUnmute, Volume, Fullscreen } = controls
const { formatTime } = utils


// import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
// import IIIFTileLayer from 'react-leaflet-iiif'
// import Leaflet from 'leaflet'

const MEIManifestation = "meldterm:MEIManifestation";
const TEIManifestation = "meldterm:TEIManifestation";
//const IIIFManifestation = "meldterm:IIIFManifestation";
const VideoManifestation = "meldterm:VideoManifestation";
const AudioManifestation = "meldterm:AudioManifestation";
const ImageManifestation = "meldterm:ImageManifestation";
const TimeSensitiveImageManifestation = "meldterm:TimeSensitiveImageManifestation";

class PageTurner extends Component { 
	constructor(props) {
		super(props);
		this.tick = this.tick.bind(this);
		this.state = { 
			iiifLat: 0,
			iiifLng: 0,
			iiifZoom:13,
			lastMediaTick: 0
		}
	}
 /*
	componentDidMount() { 
		// if(this.props.graphUri) { 
		// 	const graphUri = this.props.graphUri;
		// 	this.props.fetchGraph(graphUri);
		// }
	}*/
	highlightMyHighlights(){
		if(this.props.highlight) this.highlightThings(this.props.highlight);
	}
	highlightThings(highlight){
		if(highlight) {
			var found = 0;
			for(var i=0; i<highlight.length; i++){
				var uri = highlight[i];
				var frag = uri.substring(uri.indexOf("#")+1);
				var el = document.getElementById(frag);
				if(el) {
					el.classList.add("meld-highlight");
					found++;
				}
			}
			if(found===0) {
				// Fixme: this is very trusting!
				console.log("broken");
				window.setTimeout(this.highlightThings.bind(this, highlight), 500);
			} 
		}
	}
	componentDidUpdate(){
		if(this.props.highlight){
			this.highlightThings(this.props.highlight);
//			window.setTimeout(this.highlightMyHighlights.bind(this), 250);
		}
	}

  updateDimensions() {
    this.setState({width: document.documentElement.clientWidth, height: document.documentElement.clientHeight});
  }
  componentWillMount() {
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
	updateViewer(show, highlight){
		console.log("Calling updateViewer with ", show, highlight)
		this.props.updateViewer(show, highlight);
	}
	render() { 
		// Build an array of JSX objects corresponding to the annotation targets in our topLevel
		if(this.props.graph.outcomes.length > 6 && this.props.graph.outcomes.every(x => ('@graph' in x || '@id' in x) )){
			var outcomes = this.props.graph.outcomes.map(x => ('@graph' in x ? x['@graph'] : (['@id'] in x ? [x] : false)));
			var show = this.props.show;
			if(show.length>1) show = [show[0]];
			var highlight = this.props.highlight;
			var MEIs = outcomes[1] ? outcomes[1].map(x=>x['@id']) : [];
			var TEIs = outcomes[2] ? outcomes[2].map(x=>x['@id']) : [];
			var audios = outcomes[3] ? outcomes[3].map(x=>x['@id']) : [];
			var videos = outcomes[4] ? outcomes[4].map(x=>x['@id']) : [];
			var images = outcomes[5] ? outcomes[5].map(x=>x['@id']) : [];
			var tsImages = outcomes[6] ? outcomes[6].map(x=>x['@id']) : [];
			if(outcomes[0] && !show){
				show = [];
				var allAnnotations = outcomes[0];
				for(var i=0; i<allAnnotations.length; i++){
					if(this.props.annotation===allAnnotations[i]){
						var activeAnnotation = allAnnotations[i][pref.oa+"hasBody"];
						for(var j=0; j<activeAnnotation.length; j++){
							show.push(activeAnnotation[j]['@id']);
							if(activeAnnotation[j][pref.meldterm+'highlight']){
								if(!highlight) highlight = [];
								highlight = highlight.concat(activeAnnotation[j][pref.meldterm+'highlight']);
							}
						}
						break;
					}
				}
			}/*
			var targetIds = Object.keys(this.props.captions);
			var prevElement = false;
			var nextElement = false;
			if(show){
				let pos = targetIds.indexOf(show[0]);
				let last = targetIds[targetIds.length-1];
				if(pos>-1){
					var prevPos = pos ? targetIds[pos-1] : last;					
					var nextPos = show[0]==last ? targetIds[0] : targetIds[pos+1];
					prevElement = this.callbackMaker([prevPos], false);
					nextElement = this.callbackMaker([nextPos], false);
				}
			}*/
			var imageSet = images.concat(tsImages).concat(MEIs);
			imageSet.sort((x, y)=>(x.indexOf('.mei')-y.indexOf('.mei'))); // weird, but for now, makes sure images come before scores
			const position = [this.state.iiifLat, this.state.iiifLng];
			return ( 
				<div className="wrapper">
          <div className="megaBox pageTurner image-block img-viewer-block m-t-1col dg-bg">
			      <div className="img-viewer-block-inner">
					{ this.props.definition ?
						<div id="defTarget"><h3>{this.props.definition.head}</h3><p>{this.props.definition.definition}</p></div>
						: <div/> }
							{images.map((id) => {
								var applies = (show && show.indexOf(id)!==-1);
								var pos = imageSet.indexOf(id);
								var stripHeight = Math.min(this.state.height/2, Math.max(this.state.height/4, 250));
								if(applies){
									return (<div className="Image">
														<MyImage key={ id } uri={ id } height={ this.state.height - stripHeight -53 } pos={pos}
																		 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />
													</div>);
								}})}
							{audios.map((id) =>{
								var applies = (show && show.find(uri=>pathMatch(uri, id)));
								if(applies) {
									return (
										<Media key={id}>
											<div className="media">
												<div className="media-player">
													<Player src={id} onTimeUpdate={ (t) => {this.tick(id, t)} } />
												</div>
												<div className="media-controls right">
													<PlayPause/>
													<CurrentTime/>
													<SeekBar/>
													<Duration/>
											</div>
											</div>
										</Media>
									)
								}})}
							
							{tsImages.map((id) =>{
								var applies = (show && show.find(uri=>pathMatch(uri, id)));
								var stripHeight = Math.min(this.state.height/2, Math.max(this.state.height/4, 250));
								var pos = imageSet.indexOf(id);
								if(applies){
									var clockProvider = id.replace("#clock","");
									var defaultImage = (clockProvider in this.props.imageDefaults ? this.props.imageDefaults[clockProvider] : undefined) ;
									return <TimeSensitiveImage key={ id } id={ id } offset={0} uri={defaultImage}
																						 clockProvider = { clockProvider } height={ this.state.height - stripHeight - 53 } pos={pos}
																						 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />;
								}
							})}
						</div></div></div>);

		}/*
		if(this.props.graph.targetsById) { 
			var show = this.props.show;
			var highlight = this.props.highlight;
      const byId = this.props.graph.targetsById;
			if(this.props.annotation && !show){
				if(!show) show = [];
				var allAnnotations = this.props.graph.annoGraph["ldp:contains"][0]["oa:hasBody"];
				for(var i=0; i<allAnnotations.length; i++){
					if(this.props.annotation===allAnnotations[i]['@id']){
						var activeAnnotation = allAnnotations[i]['oa:hasBody'];
						for(var j=0; j<activeAnnotation.length; j++){
							show.push(activeAnnotation[j]['@id']);
							if(activeAnnotation[j]['meldterm:highlight']){
								if(!highlight) highlight = [];
								highlight = highlight.concat(activeAnnotation[j]['meldterm:highlight']);
							}
						}
//						window.setTimeout(this.highlightThings.bind(this, highlight), 400);
						break;
					}
				}
			}
			var imageSet = byId ? Object.keys(byId).filter((x)=> ((show && show.indexOf(x)>-1) && (byId[x].type===ImageManifestation || byId[x].type===MEIManifestation || byId[x].type===TimeSensitiveImageManifestation))): [];
			imageSet.sort((x, y)=>(x.indexOf('.mei')-y.indexOf('.mei'))); // weird, but for now, makes sure images come before scores
			const position = [this.state.iiifLat, this.state.iiifLng];
			return ( 
					<div className="wrapper">
            <div className="megaBox pageTurner image-block img-viewer-block m-t-1col dg-bg">
			       <div className="img-viewer-block-inner">
					{ this.props.definition ?
						<div id="defTarget"><h3>{this.props.definition.head}</h3><p>{this.props.definition.definition}</p></div>
						: <div/> }
					{Object.keys(byId).map( (id) => {
						var applies = (show && show.indexOf(id)!==-1);
						var pos = imageSet.indexOf(id);
					switch(byId[id]["type"]) { 
						case AudioManifestation:
							if(applies) {
								return (
									<Media key={id}>
										<div className="media">
											<div className="media-player">
												<Player src={id} onTimeUpdate={ (t) => {this.tick(id, t)} } />
											</div>
										<div className="media-controls right ">
												<PlayPause/>
												<CurrentTime/>
												<SeekBar/>
												<Duration/>
											</div>
										</div>
									</Media>
								
								)
							} else {
								break;
							}
						case TimeSensitiveImageManifestation:
							if(applies){
								return <TimeSensitiveImage key={ id } id={ id } clockProvider = { id.replace("#clock","") }  pos={pos} />;
							} else {
								break;
							}
						case ImageManifestation:
							var stripHeight = Math.min(this.state.height/2, Math.max(this.state.height/4, 250));

							if(applies) {
								return (<div className="Image">
									<MyImage key={ id } uri={ id } height={ this.state.height - stripHeight -53 } pos={pos}
								width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />
												</div>);
							} else {
								break;
							}
					}
				})}
				</div>
					</div></div>
			);
	  }*/
		return (<div> Loading...  </div>);
	}
		
	tick(id,t) {
		console.log("Tick: ", t.currentTime, this.state.lastMediaTick)
		if(Math.floor(t.currentTime) > this.state.lastMediaTick || // if we've progressed across the next second boundary, 
			 t.currentTime < this.state.lastMediaTick) { // OR if we've gone back in time (user did a seek)...
			this.setState({ lastMediaTick: Math.floor(t.currentTime) }); // keep track of this time tick)
			// dispatch a "TICK" action 
			// any time-sensitive component subscribes to it, 
			// triggering time-anchored annotations triggered as appropriate
			this.props.tickTimedResource(id, Math.floor(t.currentTime));
		}
	}
};

function trimFragAndQuery(path){
	// This is easy with URL objects, but I'm using relative paths (for
	// portability of data)
	var pathEnd = path.length;
	if(path.indexOf('?')>-1){
		pathEnd = path.indexOf('?');
	} else if(path.indexOf('#')>-1){
		pathEnd = path.indexOf('#');
	}
	return path.substring(0, pathEnd);
}
function pathMatch (path1, path2){
	return trimFragAndQuery(path1) === trimFragAndQuery(path2);
}

function mapStateToProps({ graph , score, pieces, timesync}) {
	return { graph , score , pieces, timesync};
}

function mapDispatchToProps(dispatch) { 
	return bindActionCreators({ fetchGraph, fetchTargetExpression, tickTimedResource}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTurner);



