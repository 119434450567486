import React, { Component } from 'react';
import { connect } from 'react-redux' ;
import { bindActionCreators } from 'redux';
import { registerTraversal, traverse, setTraversalObjectives, checkTraversalObjectives } from 'meld-clients-core/lib/actions/index';
import {prefix as pref} from 'meld-clients-core/lib/library/prefixes';
import { parse } from 'querystring';
import App from '../app';
import Megabox from '../megabox';
import PageTurner from '../embedded-page-turner';
import { fetchGraph } from 'meld-clients-core/lib/actions/index';
const MAX_TRAVERSERS = 30;


class DeliusEssay extends Component { 
	constructor(props) {
		super(props);
		this.defaults = {};
		this.state={
			definition: false,
			articleDefinition: {head: "Tip", definition:"Click on bold, underlined text to show relevant materials. Try selecting curly-underlined technical terms and double-underlined person names for more information."},
			show: false,
			highlight: false,
			graphUri: (window.location && window.location.search ?
											(window.location.search ==="?Brigg-Fair" ? "https://bl.linkedmusic.org/resources/Brigg-Fair.json-ld"
											 : (window.location.search ==="?Late-Swallows" ? "https://bl.linkedmusic.org/resources/Late-Swallows.json-ld"
													: "https://bl.linkedmusic.org/resources/delius-in-performance.json-ld"))
								 : "https://bl.linkedmusic.org/resources/delius-in-performance.json-ld"),
			popup: false,
			megaboxCaptions: {
				"https://bl.linkedmusic.org/resources/mei/Late-Swallows-opening.mei": "The opening of ‘Late Swallows’ (edited by Professor Daniel Grimley)",
				"https://bl.linkedmusic.org/resources/audio/Late-Swallows-Villiers-opening.mp3" : "The opening of ‘Late Swallows’, played by the Villiers Quartet (recording for Naxos in 2016).",
				"https://bl.linkedmusic.org/resources/audio/Late-Swallows-opening-vln1.mp4": "Oxford music student Athena Hawksley-Walker playing the opening phrase of ‘Late Swallows’ at a workshop with the Villiers Quartet in February 2018. Multiple bows are needed for this phrase, despite the bowing marks.",
				"https://bl.linkedmusic.org/resources/images/villiers-score.png": "The opening of ‘Late Swallows’, as marked up for performance by the Villiers Quartet.",
				"https://bl.linkedmusic.org/resources/images/ms_mus_1745!2!5_146v.jpg": "The first page of Delius‘s autograph manuscript of ‘Late Swallows’",
				"https://bl.linkedmusic.org/resources/images/late-swallows-ms-opening.png": "A detail from the first page of Delius‘s autograph manuscript of ‘Late Swallows’",
				"https://bl.linkedmusic.org/resources/images/late-swallows-ms-opening-boxed.png": "The opening long slur in Delius's autograph manuscript of ‘Late Swallows’ (red box) is too long to be a practical bowing indication"
			},
			personPages: {
				"dbp:Thomas_Beecham": "http://www.bl.uk/people/thomas-beecham-conductor",
				"dbp:Frederick_Delius": "http://www.bl.uk/people/frederick-delius",
			}
		}
	}
	updateViewer(show, highlight){
		this.setState({show: show, highlight: highlight, articleDefinition: false});
	}
	currentDefinition(definition){
		if(this.state){
			this.setState({definition: definition});
		}
	}
	callbackMaker(show, highlight){
		return function(){this.updateViewer(show, highlight)}.bind(this);
	}
	clearDefinition(){
		this.setState({definition: false});
	}
	getGraphUri(){
		if(window.location){
			switch(window.location.search) {
				case "?Brigg-Fair":
					return "https://bl.linkedmusic.org/resources/Brigg-Fair.json-ld";
				case "?Late-Swallows":
					return "https://bl.linkedmusic.org/resources/Late-Swallows.json-ld";
				default:
					return "https://bl.linkedmusic.org/resources/delius-in-performance.json-ld";
			}
		}
		return "https://bl.linkedmusic.org/resources/delius-in-performance.json-ld";
	}
	definitionFunction(def){
		return function(){
			if(this.state.articleDefinition.head==def.head){
				this.setState({articleDefinition: false});
			} else {
				this.setState({articleDefinition: def});
			}
		}.bind(this);
	}
	definitionRemoverFunction(){
		return function(){
			this.setState({articleDefinition:false});
		}.bind(this);
	}
	personFunction(person){
		// Put this in definition, so that they're mutually exclusive
		return function(){
			if(this.state.articleDefinition.name==person.name){
				this.setState({articleDefinition: false});
			} else {
				this.setState({articleDefinition: person})
			}
		}.bind(this);
	}
	popupActivationFunction(){
		return function(){
			this.setState({popup: true});
		}.bind(this);
	}
	popupCloseFunction(){
		return function(){
			this.setState({popup: false});
		}.bind(this);
	}
	activateDefinition(definition){
		var target = idOrFirstId(definition[pref.oa+"hasTarget"]).substring(1);
		var rubric = objOrFirstObj(definition[pref.oa+"hasBody"])[pref.meldterm+"hasDefinition"];
		var terms = document.getElementsByClassName("term "+target);
		for(var i=0; i<terms.length; i++){
			var definitionObj = {definition: rubric, head: terms[i].innerHTML};
			terms[i].onclick = this.definitionFunction(definitionObj); /// How do I want touch to work?
//			terms[i].onmouseup = this.definitionRemoverFunction(); /// How do I want touch to work?
		}
	}
	activatePersonDefinition(definition){
		var target = idOrFirstId(definition[pref.oa+"hasTarget"]);
		// no colons in classes (reserved for pseudo class), so remove namespace prefix
		//		var classTarget = target.substring(target.indexOf(':')+1);
		//FIXME: stupid hack
		var classTarget = target.substring(pref.dbp.length);
		var rubric = objOrFirstObj(definition[pref.oa+"hasBody"])[pref.meldterm+"hasDefinition"];
		var terms = document.getElementsByClassName("person "+classTarget);
		var head = objOrFirstObj(definition[pref.oa+"hasBody"])[pref.meldterm+"hasHead"];
		var href = this.state.personPages[target];
		var personObject = {definition: rubric, name: target, head: head, link:href};

		for(var i=0; i<terms.length; i++){
			terms[i].onclick = this.personFunction(personObject); /// How do I want touch to work?
		}
	}
	addTimesyncPoint(targets, body, start, bodyMedia, annotation){
		if(!this.props.timesync || !"mediaResources" in this.props.timesync) this.props.timesync.mediaResources = {};
		if(!this.props.timesync.mediaResources[bodyMedia]) {
			this.props.timesync.mediaResources[bodyMedia] = {currentTime:0, times:{}};
		} else if (!('times' in this.props.timesync.mediaResources[bodyMedia])){
			this.props.timesync.mediaResources[bodyMedia].times = {}
		}
		if(start===0 || start==="0"){
			// If first sync point is at the beginning, use it as the opening image for page turner
			this.defaults[bodyMedia] = Array.isArray(targets) ? targets[0]["@id"] : targets["@id"];
		}
		if(this.props.timesync.mediaResources[bodyMedia]['times'][start]){
			this.props.timesync.mediaResources[bodyMedia]['times'][start].concat(targets);
		} else {
			this.props.timesync.mediaResources[bodyMedia]['times'][start] = targets;
		}
		return this.props.timesync.mediaResources;
	}
	timelineForImageLinks(){
		if(this.props.graph.outcomes && this.props.graph.outcomes[0]){
			// We have all the annotations here that appear in a workset. We need to filter
			var links = this.props.graph.outcomes[0]['@graph'].filter(
				(x)=>(pref.oa+"motivatedBy" in x
							&& idOrFirstId(x[pref.oa+"motivatedBy"])==pref.meldterm+"updateViewerState"));
			// ... and then add to mediaResources
			for(var i=0; i<links.length; i++){
				// For updateViewerState annotations, the body is the image,
				// and the target(s) the timed resource
				var targets = links[i][pref.oa+"hasTarget"];
				var bodies = links[i][pref.oa+"hasBody"];
				if(!Array.isArray(targets)) targets = [targets];
				if(!Array.isArray(bodies)) bodies = [bodies];
				for(var t=0; t<targets.length; t++){
					// FIXME: In the past, these were relative pathnames for
					// portability. I am removing this, because it's hard.
					//					var timedURI = new URL(targets[t]['@id'], document.location.origin);
					var timedURI =  new URL(targets[t]['@id']);
					var start = timedURI.hash.substring(3);
					this.addTimesyncPoint(bodies, targets[t], start, timedURI.origin+timedURI.pathname, links[i]);
				}
			}
		}
	}
	componentDidMount() {
//		console.log(this.state);
		this.props.setTraversalObjectives([
			{
				"@context": {
					"within": {"@reverse": pref.oa+"hasBody"}
				},
				"@type": pref.oa + "Annotation",
				"within": {
					"@type": pref.oa + "Annotation"
				}
			},
			{
				"@type": pref.meldterm + "MEIManifestation"
			},
			{
				"@type": pref.meldterm + "TEIManifestation"
			},
			{
				"@type": pref.meldterm + "AudioManifestation"
			},
			{
				"@type": pref.meldterm + "VideoManifestation"
			},
			{
				"@type": pref.meldterm + "ImageManifestation"
			},
			{
				"@type": pref.meldterm + "TimeSensitiveImageManifestation"
			}/*,
			{
				"@type": pref.oa + "Annotation",
				"http://www.w3.org/ns/oa#hasTarget": [
					{"@type": pref.compVocab + "musicExample"}
				]
			}*/

		]);
		if(this.state.graphUri){
			this.props.registerTraversal(//this.state.graphUri,
//								"http://localhost:8081/resources/delius-in-performance.json-ld",
				"https://bl.linkedmusic.org/resources/delius-in-performance.json-ld",
																	 {numHops: 2,
																		extendObjectPrefix: ["https://meld.linkedmusic.org", "http://localhost", "https://bl.linkedmusic.org"],
																		ignoreObjectPrefix: ["https://meld.linkedmusic.org/terms/", "https://dbpedia.org"]});
			
		}
	}
	updateLists(){
		if(!this.props.graph.outcomes || !this.props.graph.outcomes.length || !this.props.graph.outcomes[0] ||
			 !('@graph' in this.props.graph.outcomes[0])) return;
		if(!this.props.graph.outcomes[0]['@graph'].length) return;
		var motivation = pref.oa+"motivatedBy";
		var definitions = this.props.graph.outcomes[0]['@graph'].filter(
			(x) => (motivation in x
							? idOrFirstId(x[motivation])===pref.meldterm+"definition"
							: false));
		var people = this.props.graph.outcomes[0]['@graph'].filter(
			(x) => motivation in x 
				? idOrFirstId(x[motivation])===pref.meldterm+"personInfo"
				: false);
		for(var i=0; i<definitions.length; i++){
			this.activateDefinition(definitions[i]);
		}
		for(i=0; i<people.length; i++){
			this.activatePersonDefinition(people[i]);
		}
		this.timelineForImageLinks();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps && "graph" in prevProps) {
			if(prevProps.traversalPool.running == 1 && this.props.traversalPool.running ==0){
				// check our traversal objectives if the graph has updated
				console.log("updating – it's finished traversing");
				this.props.checkTraversalObjectives(this.props.graph.graph, this.props.graph.objectives);
				if(prevProps.graph.outcomesHash !== this.props.graph.outcomesHash) {
					console.log('rebuilding structures');
					//outcomes have changed, need to update our projections!
					this.updateLists();
				} else {
				}
			} else if(Object.keys(this.props.traversalPool.pool).length && this.props.traversalPool.running < MAX_TRAVERSERS){
				var uri = Object.keys(this.props.traversalPool.pool)[0];
				this.props.traverse(uri, this.props.traversalPool.pool[uri]);
			} else if(this.props.traversalPool.running===0){
				if(prevProps.graph.outcomesHash !== this.props.graph.outcomesHash) {
//					console.log('rebuilding structures');
					// outcomes have changed, need to update our projections!
					this.updateLists();
				} else {
				}
			}
		} else {
			console.log(this.props);
		}
		this.updateLists();
	}
	render() {
		var show = (this.state && this.state.show) ? this.state.show : ["https://bl.linkedmusic.org/resources/images/late-swallows-ms-opening.png"];
		var popupShow = ["https://bl.linkedmusic.org/resources/mei/Late-Swallows-opening.mei"]
		for(var i=0; i<show.length; i++){
			if(show[i]!=="https://bl.linkedmusic.org/resources/mei/Late-Swallows-opening.mei"){
				popupShow.push(show[i]);
			}
		}
		if(popupShow.length==1){
			popupShow.push("https://bl.linkedmusic.org/resources/images/late-swallows-ms-opening.png");
		}
		var megaboxShow = show.filter((x)=>this.state.megaboxCaptions[x]);
		if(!megaboxShow.length) megaboxShow = ["https://bl.linkedmusic.org/resources/images/late-swallows-ms-opening.png"];
		var highlight = this.state && this.state.highlight ? this.state.highlight : false;
		const qpars = parse(window.location.search.slice(1)); 
		const annotation = "annotation" in qpars ? qpars["annotation"] : "";
		return (
				<div>
				  <div className="text-block">
				    <p><strong>Manuscript evidence</strong></p>
				    <p>How true are <span className="person Thomas_Beecham">Beecham</span>’s claims that <span className="person Frederick_Delius">Delius</span> provided inadequate information regarding such matters as expression, dynamics and phrasing? The <span className="megaBox-control" onClick={this.callbackMaker(['https://bl.linkedmusic.org/resources/images/late-swallows-ms-opening-boxed.png'], false)}>manuscript</span> of the slow movement of the original version of the String Quartet (1916), ‘Late Swallows’, provides an example of unedited Delius.</p>
  			</div>
				<Megabox graphUri="https://bl.linkedmusic.org/resources/delius-in-performance.json-ld"
			show={megaboxShow} highlight={highlight} 
			updateViewer={this.updateViewer.bind(this)} 
			definition={this.state.definition} captions={this.state.megaboxCaptions}
			currentDefinition={this.currentDefinition.bind(this)}
					popupCallback={this.popupActivationFunction()}
			clearDefinition={this.clearDefinition.bind(this)}
			annotation={ annotation }
			mode={ "megabox" } />
	  			<div className="text-block">
		    <p>The score as Delius wrote it presents several problems for performers. In the opening bars for the first violin, Delius writes <span className="megaBox-control" onClick={this.callbackMaker(['https://bl.linkedmusic.org/resources/images/late-swallows-ms-opening-boxed.png'], false)}>a long slur</span>. In writing for strings, this usually indicates that the notes in the slur should be performed without changing bow, but the length of the passage here <span className="megaBox-control" onClick={this.callbackMaker(['https://bl.linkedmusic.org/resources/audio/Late-Swallows-opening-vln1.mp4'], false)}>makes that impossible</span>. Similarly, many of the slurred chords cannot be played cleanly. Performers therefore have to adapt what Delius has written to match the technical limitations of their instruments. </p>
   				</div>
	  			<div className="text-block">
		    		<p><strong>Phrasing and expression</strong></p>
				<p>Editorial changes were not limited to meeting the practical capabilities of certain instruments.
				&nbsp;<span className="person Thomas_Beecham">Beecham</span> felt that Delius also gave inadequate indications of phrasing and expression (whether a passage should be loud or soft, played smoothly or not etc). Consequently, his own copies of first editions of Delius&rsquo;s scores are peppered with his famous blue pencil markings, as his score of <em>Brigg Fair</em> reveals.&nbsp;These give many indications of, for example <span className="term crescendo">crescendo</span> and <span className="term diminuendo">diminuendo</span> not found in the original.</p>
  			</div>
				<PageTurner graphUri="https://bl.linkedmusic.org/resources/delius-in-performance.json-ld"
			show={["https://bl.linkedmusic.org/resources/audio/brigg-fair-p8.mp3#clock", "https://bl.linkedmusic.org/resources/audio/brigg-fair-p8.mp3"]}
										annotation={ annotation }
										imageDefaults={this.defaults}
			mode={ "megabox" } />
				{ this.state.articleDefinition &&
					(this.state.articleDefinition.name ?
					 <div className="defTarget BLarticle">
					 <h3><a href={this.state.articleDefinition.link}>{this.state.articleDefinition.head}</a></h3>
					 <p>{this.state.articleDefinition.definition}</p></div>
					 : <div className="defTarget BLarticle"><h3>{this.state.articleDefinition.head}</h3>
					 <p>{this.state.articleDefinition.definition}</p></div>) }
				{ this.state.popup ?
		  	<App graphUri={this.state.graphUri}
					show={popupShow} highlight={highlight} popupCloseFun={this.popupCloseFunction()}
					updateViewer={this.updateViewer.bind(this)}
			definition={this.state.definition}
			currentDefinition={this.currentDefinition.bind(this)}
			clearDefinition={this.clearDefinition.bind(this)}
					annotation={ annotation } /> : false}
			</div> 
		);
	}	
};

function idOrFirstId(jld){
	return Array.isArray(jld) ? jld[0]['@id'] : jld['@id'];
}
function objOrFirstObj(jld){
	return Array.isArray(jld) ? jld[0] : jld;
}

function mapStateToProps({ graph , score, pieces, timesync, traversalPool}) {
 	return { graph, traversalPool, timesync, score, pieces };
 }

function mapDispatchToProps(dispatch) { 
 	return bindActionCreators({ registerTraversal, traverse,
															setTraversalObjectives,
															checkTraversalObjectives, fetchGraph }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliusEssay);
