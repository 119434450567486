import React, { Component } from 'react';
import { connect } from 'react-redux' ;
import { bindActionCreators } from 'redux';
import { Media, Player, controls, utils } from 'react-media-player'
import {prefix as pref} from 'meld-clients-core/lib/library/prefixes';
import Score from 'meld-clients-core/lib/containers/score';
import TEI from 'meld-clients-core/lib/containers/tei';
import MyImage from 'meld-clients-core/lib/containers/image';
//import IIIF from 'meld-clients-core/src/components/iiif';
//import TimeSensitiveImage from '../containers/timeSensitiveImage'
import TimeSensitiveImage from 'meld-clients-core/lib/containers/timeSensitiveImage'
import { registerTraversal, traverse, setTraversalObjectives, checkTraversalObjectives, fetchGraph, fetchTargetExpression, tickTimedResource } from 'meld-clients-core/lib/actions/index';
const { PlayPause, CurrentTime, Progress, SeekBar, Duration, MuteUnmute, Volume, Fullscreen } = controls
const { formatTime } = utils
const MAX_TRAVERSERS = 32;

// import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
// import IIIFTileLayer from 'react-leaflet-iiif'
// import Leaflet from 'leaflet'

const MEIManifestation = "meldterm:MEIManifestation";
const TEIManifestation = "meldterm:TEIManifestation";
//const IIIFManifestation = "meldterm:IIIFManifestation";
const VideoManifestation = "meldterm:VideoManifestation";
const AudioManifestation = "meldterm:AudioManifestation";
const ImageManifestation = "meldterm:ImageManifestation";
const TimeSensitiveImageManifestation = "meldterm:TimeSensitiveImageManifestation";

class Megabox extends Component { 
	constructor(props) {
		super(props);
		this.tick = this.tick.bind(this);
		this.state = { 
			iiifLat: 0,
			iiifLng: 0,
			iiifZoom:13,
			lastMediaTick: 0
		}
	}
	componentDidMount() { 
		window.addEventListener("resize", this.updateDimensions.bind(this));
		if(false && this.props.graphUri) { 
			const graphUri = this.props.graphUri;
			this.props.fetchGraph(graphUri);
		}
	}
	highlightMyHighlights(){
		if(this.props.highlight) this.highlightThings(this.props.highlight);
	}
	highlightThings(highlight){
		if(highlight) {
			var found = 0;
			for(var i=0; i<highlight.length; i++){
				var uri = highlight[i];
				var frag = uri.substring(uri.indexOf("#")+1);
				var el = document.getElementById(frag);
				if(el) {
					el.classList.add("meld-highlight");
					found++;
				}
			}
			if(found===0) {
				// Fixme: this is very trusting!
				console.log("broken");
//				window.setTimeout(this.highlightThings.bind(this, highlight), 500);
			} 
		}
	}
	updateLists(){
	}
	componentDidUpdate(prevProps, prevState){
		if(this.props.highlight){
			this.highlightThings(this.props.highlight);
		}
	}

  updateDimensions() {
    this.setState({width: document.documentElement.clientWidth, height: document.documentElement.clientHeight});
  }
  componentWillMount() {
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
	updateViewer(show, highlight){
		this.props.updateViewer(show, highlight);
	}
	callbackMaker(show, highlight){
		return function(){this.props.updateViewer(show, highlight)}.bind(this);
	}
	render() { 
		// Build an array of JSX objects corresponding to the annotation targets in our topLevel
		var stripHeight = Math.min(this.state.height/2, Math.max(this.state.height/4, 250));
		var scale = 0.3;
		var vrvOptions = {
			breaks:'none',
//			noLayout: 1,
			ignoreLayout:1,
			adjustPageHeight:1,
			spacingStaff: 0,
			spacingSystem: 3,
			spacingLinear: 0.2,
			spacingNonLinear: 0.55,
			noFooter: 1,
			noHeader: 1,
			scale: scale*100,
			landscape: 1,
			pageHeight: (this.state.height - stripHeight - 80) / scale,
			pageWidth: 60000//((this.state.width -200) / 2 ) / scale -100
		}
		if(this.props.graph.outcomes.length > 6 && this.props.graph.outcomes.every(x => ('@graph' in x || '@id' in x) )){
			var outcomes = this.props.graph.outcomes.map(x => ('@graph' in x ? x['@graph'] : (['@id'] in x ? [x] : false)));
			var show = this.props.show;
			if(show.length>1) show = [show[0]];
			var highlight = this.props.highlight;
			var MEIs = outcomes[1] ? outcomes[1].map(x=>x['@id']) : [];
			var TEIs = outcomes[2] ? outcomes[2].map(x=>x['@id']) : [];
			var audios = outcomes[3] ? outcomes[3].map(x=>x['@id']) : [];
			var videos = outcomes[4] ? outcomes[4].map(x=>x['@id']) : [];
			var images = outcomes[5] ? outcomes[5].map(x=>x['@id']) : [];
			var tsImages = outcomes[6] ? outcomes[6].map(x=>x['@id']) : [];
			if(outcomes[0] && !show){
				show = [];
				var allAnnotations = outcomes[0];
				for(var i=0; i<allAnnotations.length; i++){
					if(this.props.annotation===allAnnotations[i]){
						var activeAnnotation = allAnnotations[i][pref.oa+"hasBody"];
						for(var j=0; j<activeAnnotation.length; j++){
							show.push(activeAnnotation[j]['@id']);
							if(activeAnnotation[j][pref.meldterm+'highlight']){
								if(!highlight) highlight = [];
								highlight = highlight.concat(activeAnnotation[j][pref.meldterm+'highlight']);
							}
						}
						break;
					}
				}
			}
			var targetIds = Object.keys(this.props.captions);
			var prevElement = false;
			var nextElement = false;
			if(show){
				let pos = targetIds.indexOf(show[0]);
				let last = targetIds[targetIds.length-1];
				if(pos>-1){
					var prevPos = pos ? targetIds[pos-1] : last;					
					var nextPos = show[0]==last ? targetIds[0] : targetIds[pos+1];
					prevElement = this.callbackMaker([prevPos], false);
					nextElement = this.callbackMaker([nextPos], false);
				}
			}
			var imageSet = images.concat(tsImages).concat(MEIs);
			imageSet.sort((x, y)=>(x.indexOf('.mei')-y.indexOf('.mei'))); // weird, but for now, makes sure images come before scores
			const position = [this.state.iiifLat, this.state.iiifLng];
			return ( 
					<div className="wrapper">
            <div className="megaBox carousel image-block img-viewer-block m-t-1col dg-bg">
					{prevElement ? <div className="prevArrow arrowbox" onClick={prevElement}/> : <div/>}
			    <div className="img-viewer-block-inner">
					{ this.props.definition ?
						<div id="defTargetMegaBox"><h3>{this.props.definition.head}</h3><p>{this.props.definition.definition}</p></div>
						: <div/> }
						{MEIs.map((id) => {
							var applies = (show && show.indexOf(id)!==-1);
							if(applies) {
								return <Score highlight={this.props.highlight} key={ id } uri={ id }
															options={vrvOptions}
															//annotations={ this.props.graph.outcomes[0] }
															width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} height={this.state.height - stripHeight - 100} />;
							}
						})}
						{TEIs.map((id) => {
							var applies = (show && show.indexOf(id)!==-1);
							if(applies){
								return <TEI key={ id } uri={ id } height={ stripHeight } width={this.state.width - 50}
														adjustToSize={true}
														annotations={ this.props.graph.outcomes[0] }  updateViewer={ this.updateViewer.bind(this) }  highlight={ highlight } currentDefinition={ this.props.currentDefinition } clearDefinition={this.props.clearDefinition} annotation={ this.props.annotation }/>;
							}})}
						{videos.map((id) => {
							var applies = (show && show.indexOf(id)!==-1);
							if(applies){
								return <Media key={ id }>
									<div className="media-player video">
									<Player src={id} />
									<PlayPause/>
									</div>
											 </Media>;
							}
						})}
						{images.map((id) => {
							var applies = (show && show.indexOf(id)!==-1);
							var pos = imageSet.indexOf(id);
							if(applies){
								return (<div className="Image">
													<MyImage key={ id } uri={ id } height={ this.state.height - stripHeight -53 } pos={pos}
																	 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />
												</div>);
							}})}
						{audios.map((id) =>{
							var applies = (show && show.indexOf(id)!==-1);
							if(applies) {
								return (
									<Media key={id}>
										<div className="media">
											<div className="media-player">
												<Player src={id} onTimeUpdate={ (t) => {this.tick(id, t)} } />
											</div>
										<div className="media-controls">
												<PlayPause/>
												<CurrentTime/>
												<SeekBar/>
												<Duration/>
											</div>
										</div>
									</Media>
								)
							}})}

						{tsImages.map((id) =>{
							var applies = (show && show.indexOf(id)!==-1);
							var pos = imageSet.indexOf(id);
							if(applies){
								return <TimeSensitiveImage key={ id } id={ id } clockProvider = { id.replace("#clock","") } height={ this.state.height - stripHeight - 53 } pos={pos}
																					 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} offset="3" />;
							}
						})}
						{/*Object.keys(byId).map( (id) => {
						var applies = (show && show.indexOf(id)!==-1);
						var pos = imageSet.indexOf(id);
					switch(byId[id]["type"]) { 
						case MEIManifestation:
							if(applies) {
								return <Score highlight={this.props.highlight} key={ id } uri={ id } options={vrvOptions} annotations={ byId[id]["annotations"] } width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} height={this.state.height - stripHeight - 100} />;
							} else break;
						case TEIManifestation:
							if(applies){
								return <TEI key={ id } uri={ id } height={ stripHeight } width={this.state.width} annotations={ byId[id]["annotations"] }  updateViewer={ this.updateViewer.bind(this) }  highlight={ highlight } currentDefinition={ this.props.currentDefinition } clearDefinition={this.props.clearDefinition} annotation={ this.props.annotation }/>;
							} else break;
						// case IIIFManifestation:
						// 		return <IIIF key={ id } url={ id } />
						case VideoManifestation:
							if(applies){
								//								return <MediaPlayer key={ id } uri={ id } />;
								return <Media key={ id }>
									<div className="media-player video">
									<Player src={id} />
									<PlayPause/>
									</div>
									</Media>;
							}
						case AudioManifestation:
							if(applies) {
								return (
									<Media key={id}>
										<div className="media">
											<div className="media-player">
												<Player src={id} onTimeUpdate={ (t) => {this.tick(id, t)} } />
											</div>
										<div className="media-controls">
												<PlayPause/>
												<CurrentTime/>
												<SeekBar/>
												<Duration/>
											</div>
										</div>
									</Media>
								
								)
							} else {
								break;
							}
						case TimeSensitiveImageManifestation:
							if(applies) {
								return <TimeSensitiveImage key={ id } id={ id } clockProvider = { id.replace("#clock","") } height={ this.state.height - stripHeight - 53 } pos={pos}
												 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />;
							} else { 
								break;
							}
						case ImageManifestation:
							if(applies) {
								return (<div className="Image">
									<MyImage key={ id } uri={ id } height={ this.state.height - stripHeight -53 } pos={pos}
								width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />
												</div>);
							} else {
								break;
							}
					}
						})}*/}
				</div>
					{nextElement ? <div className="nextArrow arrowbox" onClick={nextElement}/> : false}
					<p className="img-viewer-block-desc p-b-1col">{show ? this.props.captions[show[0]] : ''}</p>
					<a id="popupButton" onClick={this.props.popupCallback} className="action-button img-viewer-trigger launch-image-viewer"><span className="action-text">Explore items</span></a>
					</div></div>
			);
		}
		return (<div> Loading...  </div>);
	}
		
	tick(id,t) {
		console.log("Tick: ", t.currentTime, this.state.lastMediaTick)
		if(Math.floor(t.currentTime) > this.state.lastMediaTick || // if we've progressed across the next second boundary, 
			 t.currentTime < this.state.lastMediaTick) { // OR if we've gone back in time (user did a seek)...
			this.setState({ lastMediaTick: Math.floor(t.currentTime) }); // keep track of this time tick)
			// dispatch a "TICK" action 
			// any time-sensitive component subscribes to it, 
			// triggering time-anchored annotations triggered as appropriate
			this.props.tickTimedResource(id, Math.floor(t.currentTime));
		}
	}
};


function mapStateToProps({ graph , score, pieces, timesync, traversalPool}) {
	return { graph , score , pieces, timesync, traversalPool};
}

function mapDispatchToProps(dispatch) { 
	return bindActionCreators({ registerTraversal, traverse,
															setTraversalObjectives,
															checkTraversalObjectives,
															fetchGraph, fetchTargetExpression, tickTimedResource}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Megabox);



