import React, { Component } from 'react';
import { connect } from 'react-redux' ;
import { bindActionCreators } from 'redux';
import { Media, Player, controls, utils } from 'react-media-player'
import {prefix as pref} from 'meld-clients-core/lib/library/prefixes';
import Score from 'meld-clients-core/lib/containers/score';
//import TEI from '../containers/tei'; //<!-- Try moving this
import TEI from 'meld-clients-core/lib/containers/tei';
import MyImage from 'meld-clients-core/lib/containers/image';
///import IIIF from 'meld-clients-core/src/components/iiif';
import TimeSensitiveImage from 'meld-clients-core/lib/containers/timeSensitiveImage'
import { fetchGraph, fetchTargetExpression, tickTimedResource } from 'meld-clients-core/lib/actions/index';

const { PlayPause, CurrentTime, Progress, SeekBar, Duration, MuteUnmute, Volume, Fullscreen } = controls
const { formatTime } = utils


// import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
// import IIIFTileLayer from 'react-leaflet-iiif'
// import Leaflet from 'leaflet'

class App extends Component { 
	constructor(props) {
		super(props);
		this.tick = this.tick.bind(this);
		this.state = { 
			iiifLat: 0,
			iiifLng: 0,
			iiifZoom:13,
			lastMediaTick: 0,
			definition: {head: "Tip", definition: "Click on bold, colored text to view different materials. Hover over underlined text for glossary"}
		}
	}
 
	componentDidMount() { 
		window.addEventListener("resize", this.updateDimensions.bind(this));
/*		if(this.props.graphUri) { 
			const graphUri = this.props.graphUri;
			this.props.fetchGraph(graphUri);
		}*/
	}
	highlightMyHighlights(){
		if(this.props.highlight) this.highlightThings(this.props.highlight);
	}
	highlightThings(highlight){
		if(highlight) {
			var found = 0;
			for(var i=0; i<highlight.length; i++){
				var uri = highlight[i];
				var frag = uri.substring(uri.indexOf("#")+1);
				var el = document.getElementById(frag);
				if(el) {
					el.classList.add("meld-highlight");
					found++;
				}
			}
			if(found===0) {
				// Fixme: this is very trusting!
				console.log("broken", highlight);
//				window.setTimeout(this.highlightThings.bind(this, highlight), 500);
			} 
		}
	}
	componentDidUpdate(){
		if(this.props.highlight){
			this.highlightThings(this.props.highlight);
//			window.setTimeout(this.highlightMyHighlights.bind(this), 250);
		}
	}
  updateDimensions() {
    this.setState({width: document.documentElement.clientWidth, height: document.documentElement.clientHeight});
  }
  componentWillMount() {
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
	setDefinitionFun(){
		return function(definition){
			console.log(this.state, definition);
			if(this.state) this.setState({definition: definition});
		}.bind(this);
	}
	clearDefinitionFun(){
		return function(){
			if(this.state) this.setState({definition: false});
		}.bind(this);
	}
	updateViewer(show, highlight){
		console.log("Calling updateViewer with ", show, highlight)
		this.props.updateViewer(show, highlight);
	}
	IDsFromGraphOrSingleton(index){
		if(this.props.graph.outcomes.length>index && this.props.graph.outcomes[index]){
			const outcome = this.props.graph.outcomes[index];
			if('@graph' in outcome){
				return outcome['@graph'].map(x=>x['@id']);
			} else if(outcome['@id']){
				return [outcome['@id']];
			} else return [];
		}
		return [];
	}
	render() { 
		// Build an array of JSX objects corresponding to the annotation targets in our topLevel
		var stripHeight = Math.min(this.state.height/2, Math.max(this.state.height/4, 250));
		var scale = this.state.height > 700 ? 0.3 : this.state.height > 600 ? 0.2 : 0.15;
		var vrvOptions = {
			breaks:'none',
//			noLayout: 1,
			adjustPageHeight:1,
			spacingStaff: 0,
			spacingSystem: 3,
			spacingLinear: 0.2,
			spacingNonLinear: 0.55,
			noFooter: 1,
			noHeader: 1,
			scale: scale*100,
			pageHeight: (this.state.height - stripHeight - 80) / scale,
			pageWidth: ((this.state.width -200) / 2 ) / scale -100
		}
		if(this.props.graph.outcomes.length>6){
			var show = this.props.show;
//			if(show.length>1) show = [show[0]];
			var highlight = this.props.highlight;
			var MEIs = this.IDsFromGraphOrSingleton(1); //this.props.graph.outcomes[1] ? this.props.graph.outcomes[1]['@graph'].map(x=>x['@id']) : [];
			var TEIs = this.IDsFromGraphOrSingleton(2); //this.props.graph.outcomes[2] ? this.props.graph.outcomes[2]['@graph'].map(x=>x['@id']) : [];
			var audios = this.IDsFromGraphOrSingleton(3); //this.props.graph.outcomes[3] ? this.props.graph.outcomes[3]['@graph'].map(x=>x['@id']) : [];
			var videos = this.IDsFromGraphOrSingleton(4); //this.props.graph.outcomes[4] ? this.props.graph.outcomes[4]['@graph'].map(x=>x['@id']) : [];
			var images = this.IDsFromGraphOrSingleton(5); //this.props.graph.outcomes[5] ? this.props.graph.outcomes[5]['@graph'].map(x=>x['@id']) : [];
			var tsImages = this.IDsFromGraphOrSingleton(6); //this.props.graph.outcomes[6] ? this.props.graph.outcomes[6]['@graph'].map(x=>x['@id']) : [];
			console.log(MEIs, TEIs, audios, videos, images, tsImages, "<---!");
			if(this.props.graph.outcomes[0] && !show){
				show = [];
				var allAnnotations = this.props.graph.outcomes[0]['@graph'];
				for(var i=0; i<allAnnotations.length; i++){
					if(this.props.annotation===allAnnotations[i]){
						var activeAnnotation = allAnnotations[i][pref.oa+"hasBody"];
						for(var j=0; j<activeAnnotation.length; j++){
							show.push(activeAnnotation[j]['@id']);
							if(activeAnnotation[j][pref.meldterm+'highlight']){
								if(!highlight) highlight = [];
								highlight = highlight.concat(activeAnnotation[j][pref.meldterm+'highlight']);
							}
						}
						break;
					}
				}
			}
/*
			var targetIds = Object.keys(this.props.captions);
			var prevElement = false;
			var nextElement = false;
			if(show){
				let pos = targetIds.indexOf(show[0]);
				let last = targetIds[targetIds.length-1];
				if(pos>-1){
					var prevPos = pos ? targetIds[pos-1] : last;					
					var nextPos = show[0]==last ? targetIds[0] : targetIds[pos+1];
					prevElement = this.callbackMaker([prevPos], false);
					nextElement = this.callbackMaker([nextPos], false);
				}
			}*/
			var imageSet = images.concat(tsImages).concat(MEIs);
			imageSet.sort((x, y)=>(x.indexOf('.mei')-y.indexOf('.mei'))); // weird, but for now, makes sure images come before scores
			const position = [this.state.iiifLat, this.state.iiifLng];
			return (
				<div className="wrapper deliusapp resizing">
					<div className="background" />
					<div className="inner">
						<ul className="bl"><li><a id="img-close" className="close action-button" onClick={this.props.popupCloseFun}><span className="icon icon-sml sml-cls-w"></span><span className="action-text">Close viewer</span></a></li></ul>
						{MEIs.map((id) => {
							var applies = (show && show.indexOf(id)!==-1);
							if(applies) {
								return <Score highlight={this.props.highlight} key={ id } uri={ id }
															options={vrvOptions}
															//annotations={ this.props.graph.outcomes[0] }
															width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} height={this.state.height - stripHeight - 100} />;
							}
						})}
						{TEIs.map((id) => {
							var applies = true || (show && show.indexOf(id)!==-1); // We always want to show this
							if(applies){
								return <TEI key={ id } uri={ id } height={ stripHeight } width={this.state.width} annotations={ this.props.graph.outcomes[0]['@graph'] }
														updateViewer={ this.updateViewer.bind(this) }  highlight={ highlight } currentDefinition={ this.setDefinitionFun() } clearDefinition={this.clearDefinitionFun()}
														showAnnotations="true"
														//annotation={ this.props.annotation }
											 />;
							}})}
						{videos.map((id) => {
							var applies = (show && show.indexOf(id)!==-1);
							if(applies){
								return <Media key={ id }>
									<div className="media-player video"  style={{"marginLeft": ((this.state.width/2 - 537)/2)+"px"}}>
									<Player src={id} />
									<PlayPause/>
									</div>
											 </Media>;
							}
						})}
						{images.map((id) => {
							var applies = (show && show.indexOf(id)!==-1);
							var pos = imageSet.indexOf(id);
							if(applies){
								return (<div className="Image">
													<MyImage key={ id } uri={ id } height={ this.state.height - stripHeight -53 } pos={pos}
																	 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />
												</div>);
							}})}
						{audios.map((id) =>{
							var applies = (show && show.indexOf(id)!==-1);
							if(applies) {
								return (
									<Media key={id}>
										<div className="media">
											<div className="media-player">
												<Player src={id} onTimeUpdate={ (t) => {this.tick(id, t)} } />
											</div>
										<div className="media-controls right">
												<PlayPause/>
												<CurrentTime/>
												<SeekBar/>
												<Duration/>
											</div>
										</div>
									</Media>
								)
							}})}

						{tsImages.map((id) =>{
							var applies = (show && show.indexOf(id)!==-1);
							var pos = imageSet.indexOf(id);
							if(applies){
								return <TimeSensitiveImage key={ id } id={ id } clockProvider = { id.replace("#clock","") } height={ this.state.height - stripHeight - 53 } pos={pos}
																					 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} offset="3"/>;
							}
						})}
						
					{ this.state.definition &&
						<div id="defTargetPopup"><h3>{this.state.definition.head}</h3><p>{this.state.definition.definition}</p></div> }
					</div></div>
			);
		}/*
		if(this.props.graph.targetsById) { 
			var show = this.props.show;
			var highlight = this.props.highlight;
      const byId = this.props.graph.targetsById;
			if(this.props.annotation && !show){
				if(!show) show = [];
				var allAnnotations = this.props.graph.annoGraph["ldp:contains"][0]["oa:hasBody"];
				for(var i=0; i<allAnnotations.length; i++){
					if(this.props.annotation===allAnnotations[i]['@id']){
						var activeAnnotation = allAnnotations[i]['oa:hasBody'];
						for(var j=0; j<activeAnnotation.length; j++){
							show.push(activeAnnotation[j]['@id']);
							if(activeAnnotation[j]['meldterm:highlight']){
								if(!highlight) highlight = [];
								highlight = highlight.concat(activeAnnotation[j]['meldterm:highlight']);
							}
						}
//						window.setTimeout(this.highlightThings.bind(this, highlight), 400);
						break;
					}
				}
			}
			var imageSet = byId ? Object.keys(byId).filter((x)=> ((show && show.indexOf(x)>-1) && (byId[x].type===ImageManifestation || byId[x].type===MEIManifestation || byId[x].type===TimeSensitiveImageManifestation))): [];
			imageSet.sort((x, y)=>(x.indexOf('.mei')-y.indexOf('.mei'))); // weird, but for now, makes sure images come before scores
			const position = [this.state.iiifLat, this.state.iiifLng];
			console.log("***", this.props.show, imageSet, show);
			return ( 
				<div className="wrapper deliusapp resizing">
					<div className="background" />
					<div className="inner">
					<ul className="bl"><li><a id="img-close" className="close action-button" onClick={this.props.popupCloseFun}><span className="icon icon-sml sml-cls-w"></span><span className="action-text">Close viewer</span></a></li></ul>
					{Object.keys(byId).map( (id) => {
						var applies = (show && show.indexOf(id)!==-1);
						var pos = imageSet.indexOf(id);
					switch(byId[id]["type"]) { 
						case MEIManifestation:
							if(applies) {
								return <Score key={ id } uri={ id } options={vrvOptions} annotations={ byId[id]["annotations"] } highlight={this.props.highlight} width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} height={this.state.height - stripHeight - 100} />;
							} else break;;
						case TEIManifestation:
							return <TEI key={ id } uri={ id } height={ stripHeight } width={this.state.width} annotations={ byId[id]["annotations"] }  updateViewer={ this.updateViewer.bind(this) }  highlight={ highlight } currentDefinition={ this.setDefinitionFun() } clearDefinition={this.clearDefinitionFun()} annotation={ this.props.annotation }/>;
						case IIIFManifestation:
//								return <IIIF key={ id } url={ id } />
						case VideoManifestation:
							if(applies){
								//								return <MediaPlayer key={ id } uri={ id } />;
								return <Media key={ id }>
									<div className="media-player video"  style={{"marginLeft": ((this.state.width/2 - 537)/2)+"px"}}>
									<Player src={id} />
									<PlayPause/>
									</div>
									</Media>;
							}
						case AudioManifestation:
							if(applies) {
								return (
										<Media key={id}>
										<div className="media">
											<div className="media-player">
												<Player src={id} onTimeUpdate={ (t) => {this.tick(id, t)} } />
											</div>
										<div className="media-controls right ">
												<PlayPause/>
												<CurrentTime/>
												<SeekBar/>
												<Duration/>
											</div>
										</div>
									</Media>
								
								)
							} else {
								break;
							}
						case TimeSensitiveImageManifestation:
							if(applies) {
								return <TimeSensitiveImage key={ id } id={ id } clockProvider = { id.replace("#clock","") } height={ this.state.height - stripHeight - 53 } pos={pos}
												 width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />;
							} else { 
								break;
							}
						case ImageManifestation:
							if(applies) {
								return <MyImage key={ id } uri={ id } height={ this.state.height - stripHeight -53 } pos={pos}
								       width={imageSet.length>1 ? (this.state.width-150) / 2 : this.state.width-80} />;
							} else {
								break;
							}
					}
					})}
					{ this.state.definition &&
						<div id="defTargetPopup"><h3>{this.state.definition.head}</h3><p>{this.state.definition.definition}</p></div> }
				  </div>
				</div>
			);
		}*/
		return (<div> Loading...  </div>);
	}
		
	tick(id,t) {
		console.log("Tick: ", t.currentTime, this.state.lastMediaTick)
		if(Math.floor(t.currentTime) > this.state.lastMediaTick || // if we've progressed across the next second boundary, 
			 t.currentTime < this.state.lastMediaTick) { // OR if we've gone back in time (user did a seek)...
			this.setState({ lastMediaTick: Math.floor(t.currentTime) }); // keep track of this time tick)
			// dispatch a "TICK" action 
			// any time-sensitive component subscribes to it, 
			// triggering time-anchored annotations triggered as appropriate
			this.props.tickTimedResource(id, Math.floor(t.currentTime));
		}
	}
};


function mapStateToProps({ graph , score, pieces, timesync}) {
	return { graph , score , pieces, timesync};
}

function mapDispatchToProps(dispatch) { 
	return bindActionCreators({ fetchGraph, fetchTargetExpression, tickTimedResource}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);



